.h-full {
    height: 100%;
}

.max-h-full {
    max-height: 100%;
}

.h-screen {
    height: 100vh;
}

.min-h-screen {
    min-height: 100vh;
}

.max-h-screen {
    max-height: 100vh;
}


.w-full {
    width: 100%;
}

.max-w-full {
    max-width: 100%;
}

.w-screen {
    width: 100vw;
}

.min-w-screen {
    min-width: 100vw;
}

.max-w-screen {
    max-width: 100vw;
}

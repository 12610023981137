$primary-color: #009999;
$neutralDark:#201f1e !default;
$neutralPrimary:#323130 !default;
$neutralPrimaryAlt:#3b3a39 !default;
$neutralSecondary:#605e5c !default;
$neutralTertiary:#a19f9d !default;

@import 'leaflet/dist/leaflet.css';
@import "styles/theme/dimensions";
@import "styles/theme/paddings";
@import "styles/theme/overflow";
@import "styles/theme/flexbox";
@import "styles/theme/cards";
@import "styles/theme/tabs";
@import "styles/theme/tables";
@import "styles/theme/map";

html {
    /* this is acutally the component size, since rem units are used */
    //font-size: 13px;
}


body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
    padding: 0;
    margin: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.text-center {
    text-align: center;
}

.text-muted {
    color: $neutralTertiary;
}

.p-15 {
    padding: 15px;
}

.ant-tabs {
    height: 100%;
}

.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav{
    margin-bottom: 0;
}

.ant-tabs-content-holder, .ant-tabs-tabpane {
    display: flex;
}



.flex-fill > .ant-card-body {
    @extend .flex-fill
}

nz-card-loading {
    flex: 1 1 auto;
}

.ant-card-small {
    .ant-card-body, .ant-card-head {
        padding-right: 8px;
        padding-left: 8px;
    }
    .ant-card-body {
        padding-bottom: 8px;
    }
}


.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
    //  background-color: rgba(0, 120, 157, 0.3);
    background-color: rgba(70, 71, 73, 0.3);
}

.marker-cluster-small div,
.marker-cluster-medium div,
.marker-cluster-large div {
    //  background-color: rgba(0, 120, 157, 0.6);
    background-color: rgba(70, 71, 73, 0.6);
}

.marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
}

.marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;

    text-align: center;
    border-radius: 15px;
}

.marker-cluster span {
    line-height: 30px;
    color: #e0e0e0;
}

.leaflet-popup-content {
    margin: 0;
}

.leaflet-popup-content-wrapper {
    border-radius: 0;
}

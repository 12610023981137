.p-5 {
    padding: 5px;
}

.p-10 {
    padding: 10px;
}

.p-l-10 {
    padding-left: 10px;
}

.p-r-10 {
    padding-right: 10px;
}

.p-l-r-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.m-t-10 {
    margin-top: 10px;
}

.m-t-20 {
    margin-top: 20px;
}


.m-l-10 {
    margin-left: 10px;
}

.m-l-20 {
    margin-left: 20px;
}

.m-r-10 {
    margin-right: 10px;
}

.m-r-20 {
    margin-right: 20px;
}

.m-b-10 {
    margin-bottom: 10px;
}

.m-b-20 {
    margin-bottom: 20px;
}


.flex {
    display: flex;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-0 {
    flex: 0;
}

.flex-auto {
    flex: 1 1 auto;
}

.flex-fill {
    @extend .flex;
    @extend .flex-auto;
}

.flex-200 {
    flex: 0 0 200px;
}

.flex-300 {
    flex: 0 0 300px;
}

.flex-350 {
    flex: 0 0 350px;
}

.flex-20-p {
    flex: 0 0 20%;
}

.flex-30-p {
    flex: 0 0 30%;
}

.flex-40-p {
    flex: 0 0 40%;
}

.flex-50-p {
    flex: 0 0 50%;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.overflow-auto {
    overflow: auto;
}

.overflow-x-auto {
    overflow-x: auto;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

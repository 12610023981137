.table-filters {
    padding-bottom: 5px;

    nz-select {
        flex: 0 0 100px;
        margin-right: 5px;
    }

    dh-drop-down-select {
        margin-right: 5px;
    }
}

.striped-table {
    width: 100%;
    tr {
        border-bottom: 1px solid #eeeeee;
        td {
            vertical-align: top;
        }
    }
}

.details-table {
    @extend .striped-table;

    tr {
        td:first-child {
            color: rgba(0, 21, 41, 0.39)
        }
    }
}
